import React, {useState, useEffect} from 'react';
import {Disclosure} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/outline';
import {language, capitalizeFirstLetter} from "../translation/language";


const Faq1 = (props) => {
    const faq = [
        {
            number: 1,
            question: "Co to jest NNW?",
            answer: "NNW - to ubezpieczenie on wypadków nieszczęśliwych wypadków",
        },
        {
            number: 2,
            question: "Czy można kupić OC na termin 1 miesiąć?",
            answer: "W przypadku osoby fizucznej - nie. Miesięczne OC mogą kupować tylko firmy dla rejestracji samochodów na sprzedaż.",
        },
        {
            number: 3,
            question: "Czy może samochód bez OC być w garażu?",
            answer: "Nie - inazczej będzie mandat!",
        },
        {
            number: 4,
            question: "Jakie są koszty polisy OC dla młodych kierowców?",
            answer: "Koszty polisy OC dla młodych kierowców zazwyczaj są wyższe ze względu na ich brak doświadczenia. Składka zależy od wielu czynników, takich jak wiek, historia kierowcy i rodzaj pojazdu."
        },
        {
            number: 5,
            question: "Czy mogę przenieść swoją polisę OC na inny pojazd?",
            answer: "Tak, zazwyczaj możesz przenieść swoją polisę OC na inny pojazd. Musisz skontaktować się z ubezpieczycielem i dostosować polisę do nowego pojazdu."
        },
        {
            number: 6,
            question: "Czy istnieje możliwość zawarcia polisy OC na krótki okres czasu?",
            answer: "Tak, istnieją krótkoterminowe polisy OC, które można zawierać na określony okres czasu, na przykład na kilka dni lub tygodni."
        },
        {
            number: 7,
            question: "Czy OC obejmuje koszty leczenia osób trzecich po wypadku?",
            answer: "Tak, OC zazwyczaj obejmuje koszty leczenia osób trzecich po wypadku, które były spowodowane Twoim pojazdem."
        },
        {
            number: 8,
            question: "Jakie czynniki wpływają na wysokość składki OC?",
            answer: "Wysokość składki OC zależy od wielu czynników, takich jak wiek kierowcy, historia ubezpieczeń, rodzaj pojazdu, region, w którym się ubezpieczasz, i wiele innych."
        },
        {
            number: 9,
            question: "Czy muszę mieć polisę OC, jeśli mój pojazd jest wyłącznie na stanie?",
            answer: "Tak, musisz mieć polisę OC nawet jeśli Twój pojazd jest tylko na stanie. Ubezpieczenie OC jest obowiązkowe dla każdego pojazdu zarejestrowanego w Polsce."
        },
        {
            number: 10,
            question: "Czy OC pokrywa szkody spowodowane przez kierowcę nieuprawnionego?",
            answer: "Tak, OC może pokrywać szkody spowodowane przez kierowcę nieuprawnionego, ale może to zależeć od warunków polisy."
        },
        {
            number: 11,
            question: "Czy polisa OC obejmuje szkody w przypadku wypadków spowodowanych przez zwierzęta?",
            answer: "Tak, polisa OC może obejmować szkody w przypadku wypadków spowodowanych przez zwierzęta, ale również zależy od warunków polisy."
        },
        {
            number: 12,
            question: "Czy OC obejmuje koszty naprawy lub wymiany szyb w przypadku stłuczki?",
            answer: "Tak, OC może obejmować koszty naprawy lub wymiany szyb w przypadku stłuczki, jeśli jest to uwzględnione w polisie."
        },
        {
            number: 13,
            question: "Jakie są różnice między OC komunikacyjnym a OC rolniczym?",
            answer: "OC komunikacyjne jest przeznaczone dla pojazdów używanych do celów komercyjnych, podczas gdy OC rolnicze jest dedykowane pojazdom rolniczym używanym w gospodarstwach rolnych."
        },
        {
            number: 14,
            question: "Czy istnieją opcje dodatkowego ubezpieczenia w ramach OC?",
            answer: "Tak, istnieją opcje dodatkowego ubezpieczenia, które można dołączyć do polisy OC, takie jak Assistance, NNW, ochrona prawna itp."
        },
        {
            number: 15,
            question: "Czy OC obejmuje koszty holowania pojazdu po wypadku?",
            answer: "Tak, polisa OC może obejmować koszty holowania pojazdu po wypadku, ale zazwyczaj jest to uzależnione od rodzaju i warunków polisy."
        },
        {
            number: 16,
            question: "Jak długo trwa proces rozpatrywania roszczeń związanych z OC?",
            answer: "Czas trwania procesu rozpatrywania roszczeń związanych z OC może się różnić, ale zazwyczaj ubezpieczyciele starają się jak najszybciej rozstrzygnąć roszczenia."
        },
        {
            number: 17,
            question: "Czy mogę zawrzeć OC online?",
            answer: "Tak, wiele ubezpieczycieli umożliwia zawarcie polisy OC online przez ich strony internetowe."
        },
        {
            number: 18,
            question: "Czy OC można rozszerzyć na inne kraje poza granicami Polski?",
            answer: "Tak, istnieją opcje rozszerzenia polisy OC na inne kraje, jeśli planujesz podróżować za granicę."
        },
        {
            number: 19,
            question: "Czy polisa OC obejmuje szkody w przypadku wypadku podczas jazdy po alkoholu?",
            answer: "Polisa OC może nie obejmować szkód spowodowanych pod wpływem alkoholu, ale to zależy od warunków polisy i przepisów prawa."
        },
        {
            number: 20,
            question: "Czy OC pokrywa szkody spowodowane przez niebezpieczne warunki drogowe, takie jak gołoledź?",
            answer: "Tak, OC zazwyczaj pokrywa szkody spowodowane przez niebezpieczne warunki"
        }
    ];

    const faqUkrainian = [
        {
            number: 1,
            question: "Що таке NNW?",
            answer: "NNW - це страхування від нещасних випадків."
        },
        {
            number: 2,
            question: "Чи можна придбати OC на строк 1 місяць?",
            answer: "Для фізичних осіб - ні. Щомісячне ОСЦП можуть купувати лише компанії для реєстрації автомобілів на продаж."
        },
        {
            number: 3,
            question: "Чи може автомобіль без OC бути в гаражі?",
            answer: "Ні - інакше буде штраф!"
        },
        {
            number: 4,
            question: "Які витрати на поліс OC для молодих водіїв?",
            answer: "Витрати на поліс OC для молодих водіїв зазвичай вищі через їхню відсутність досвіду. Страховий внесок залежить від багатьох факторів, таких як вік, історія водія і тип транспортного засобу."
        },
        {
            number: 5,
            question: "Чи можу я перенести свій поліс OC на інший транспортний засіб?",
            answer: "Так, зазвичай ви можете перенести свій поліс ОСЦП на інший транспортний засіб. Вам потрібно зв'язатися зі страховиком і адаптувати поліс до нового транспортного засобу."
        },
        {
            number: 6,
            question: "Чи існує можливість укладення полісу OC на короткий період часу?",
            answer: "Так, існують короткострокові поліси ОСЦП, які можна укладати на визначений період часу, наприклад, на кілька днів або тижнів."
        },
        {
            number: 7,
            question: "Чи OC охоплює витрати на лікування третіх осіб після нещасного випадку?",
            answer: "Так, OC зазвичай охоплює витрати на лікування третіх осіб після нещасного випадку, які були спричинені вашим транспортним засобом."
        },
        {
            number: 8,
            question: "Які чинники впливають на висоту внеску OC?",
            answer: "Висота внеску OC залежить від багатьох чинників, таких як вік водія, історія страхування, тип транспортного засобу, регіон, в якому ви страхуєтеся, і багато інших."
        },
        {
            number: 9,
            question: "Чи повинен я мати поліс OC, якщо мій транспортний засіб лише на стої?",
            answer: "Так, вам потрібно мати поліс OC навіть якщо ваш транспортний засіб лише на стої. Страхування ОСЦП є обов'язковим для кожного транспортного засобу, зареєстрованого в Польщі."
        },
        {
            number: 10,
            question: "Чи OC покриває збитки, спричинені недозволеним водієм?",
            answer: "Так, OC може покривати збитки, спричинені недозволеним водієм, але це може залежати від умов полісу."
        },
        {
            number: 11,
            question: "Чи поліс OC охоплює збитки в разі аварій, спричинених тваринами?",
            answer: "Так, поліс OC може охоплювати збитки в разі аварій, спричинених тваринами, але це також залежить від умов полісу."
        },
        {
            number: 12,
            question: "Чи OC охоплює витрати на ремонт або заміну скла у випадку зіткнення?",
            answer: "Так, OC може охоплювати витрати на ремонт або заміну скла у випадку зіткнення, якщо це передбачено полісом."
        },
        {
            number: 13,
            question: "Які різниці між OC комунікаційним і OC рольовим?",
            answer: "ОСЦП комунікаційне призначене для транспортних засобів, що використовуються для комерційних цілей, тоді як ОСЦП рольове призначене для сільськогосподарських транспортних засобів, використовуваних на сільськогосподарських господарствах."
        },
        {
            number: 14,
            question: "Чи існують варіанти додаткового страхування в межах OC?",
            answer: "Так, існують варіанти додаткового страхування, які можна додати до полісу OC, такі як Assistance, НП, юридичний захист і т. д."
        },
        {
            number: 15,
            question: "Чи OC охоплює витрати на буксирування транспортного засобу після аварії?",
            answer: "Так, поліс OC може охоплювати витрати на буксирування транспортного засобу після аварії, але зазвичай це залежить від типу і умов полісу."
        },
        {
            number: 16,
            question: "Як довго триває процес розгляду вимог, пов'язаних з OC?",
            answer: "Тривалість процесу розгляду вимог, пов'язаних з OC, може відрізнятися, але зазвичай страховики стараються якнайшвидше вирішити вимоги."
        },
        {
            number: 17,
            question: "Чи можна укласти ОСЦП онлайн?",
            answer: "Так, багато страховиків дозволяють укладання полісу OC онлайн через їхні веб-сайти."
        },
        {
            number: 18,
            question: "Чи можна розширити OC на інші країни поза межами Польщі?",
            answer: "Так, існують опції розширення полісу OC на інші країни, якщо ви плануєте подорожувати за кордон."
        },
        {
            number: 19,
            question: "Чи охоплює поліс OC збитки в разі аварії під час водіння під впливом алкоголю?",
            answer: "Поліс OC може не охоплювати збитки, спричинені під впливом алкоголю, але це залежить від умов полісу і законодавства."
        },
        {
            number: 20,
            question: "Чи OC покриває збитки, спричинені небезпечними дорожніми умовами, такими як ожеледиця?",
            answer: "Так, OC зазвичай покриває збитки, спричинені небезпечними дорожніми умовами."
        }
    ];

    const faqEnglish = [
        {
            number: 1,
            question: "What is NNW?",
            answer: "NNW - it's insurance against accidents."
        },
        {
            number: 2,
            question: "Can I buy third-party liability insurance for one month?",
            answer: "For individuals - no. Monthly third-party liability insurance can only be purchased by companies for vehicles registered for sale."
        },
        {
            number: 3,
            question: "Can a car without third-party liability insurance be in the garage?",
            answer: "No - otherwise, there will be a fine!"
        },
        {
            number: 4,
            question: "What are the costs of third-party liability insurance for young drivers?",
            answer: "The costs of third-party liability insurance for young drivers are usually higher due to their lack of experience. The premium depends on various factors such as age, driver history, and the type of vehicle."
        },
        {
            number: 5,
            question: "Can I transfer my third-party liability insurance to another vehicle?",
            answer: "Yes, typically you can transfer your third-party liability insurance to another vehicle. You need to contact the insurer and adjust the policy for the new vehicle."
        },
        {
            number: 6,
            question: "Is it possible to get third-party liability insurance for a short period?",
            answer: "Yes, there are short-term third-party liability insurance policies that you can get for a specified period, such as a few days or weeks."
        },
        {
            number: 7,
            question: "Does third-party liability insurance cover the medical expenses of third parties after an accident?",
            answer: "Yes, third-party liability insurance usually covers the medical expenses of third parties after an accident caused by your vehicle."
        },
        {
            number: 8,
            question: "What factors influence the premium for third-party liability insurance?",
            answer: "The premium for third-party liability insurance depends on various factors such as the age of the driver, insurance history, type of vehicle, the region where you are insured, and many others."
        },
        {
            number: 9,
            question: "Do I need third-party liability insurance if my vehicle is only parked?",
            answer: "Yes, you must have third-party liability insurance even if your vehicle is only parked. Third-party liability insurance is mandatory for every vehicle registered in Poland."
        },
        {
            number: 10,
            question: "Does third-party liability insurance cover damages caused by an unauthorized driver?",
            answer: "Yes, third-party liability insurance may cover damages caused by an unauthorized driver, but it depends on the policy terms."
        },
        {
            number: 11,
            question: "Does the third-party liability insurance policy cover damages in accidents caused by animals?",
            answer: "Yes, the third-party liability insurance policy may cover damages in accidents caused by animals, but it also depends on the policy terms."
        },
        {
            number: 12,
            question: "Does third-party liability insurance cover the costs of repairing or replacing windows in case of an accident?",
            answer: "Yes, third-party liability insurance may cover the costs of repairing or replacing windows in case of an accident if it is included in the policy."
        },
        {
            number: 13,
            question: "What are the differences between communication third-party liability insurance and agricultural third-party liability insurance?",
            answer: "Communication third-party liability insurance is designed for vehicles used for commercial purposes, while agricultural third-party liability insurance is dedicated to agricultural vehicles used on farms."
        },
        {
            number: 14,
            question: "Are there options for additional coverage within third-party liability insurance?",
            answer: "Yes, there are options for additional coverage that can be added to third-party liability insurance, such as Assistance, Personal Accident Insurance (NNW), legal protection, etc."
        },
        {
            number: 15,
            question: "Does third-party liability insurance cover the costs of towing the vehicle after an accident?",
            answer: "Yes, the third-party liability insurance policy may cover the costs of towing the vehicle after an accident, but it usually depends on the type and conditions of the policy."
        },
        {
            number: 16,
            question: "How long does the process of handling third-party liability claims take?",
            answer: "The duration of the process of handling third-party liability claims can vary, but insurers typically aim to resolve claims as quickly as possible."
        },
        {
            number: 17,
            question: "Can I purchase third-party liability insurance online?",
            answer: "Yes, many insurers allow the purchase of third-party liability insurance online through their websites."
        },
        {
            number: 18,
            question: "Can third-party liability insurance be extended to other countries beyond Poland's borders?",
            answer: "Yes, there are options to extend third-party liability insurance to other countries if you plan to travel abroad."
        },
        {
            number: 19,
            question: "Does the third-party liability insurance policy cover damages in the case of an accident while driving under the influence of alcohol?",
            answer: "The third-party liability insurance policy may not cover damages caused under the influence of alcohol, but it depends on the policy terms and legal regulations."
        },
        {
            number: 20,
            question: "Does third-party liability insurance cover damages caused by hazardous road conditions, such as black ice?",
            answer: "Yes, third-party liability insurance usually covers damages caused by hazardous road conditions."
        }
    ];






    const [randomFaq, setRandomFaq] = useState([]);

    useEffect(() => {
        let currentFaq;

        if (props.language === 1) {
            currentFaq = faq;
        } else if (props.language === 0) {
            currentFaq = faqEnglish;
        } else if (props.language === 2) {
            currentFaq = faqUkrainian;
        } else {
            currentFaq = faq;
        }

        const shuffledFaq = [...currentFaq].sort(() => Math.random() - 0.5);
        const selectedFaq = shuffledFaq.slice(0, 4);

        setRandomFaq(selectedFaq);
    }, [props.language]);



    return (
        <div className="w-full bg-gray-100 p-4 rounded-lg" id={"faq"}>
            {/* Title & Image */}
            <div className="my-8 mx-auto max-w-4xl flex flex-col items-center">
                <h2 className="text-center text-4xl sm:text-5xl text-sky-800 font-bold tracking-wider">
                    {capitalizeFirstLetter(language.faq[props.language])}
                </h2>
            </div>

            {/* FAQ */}
            <dl className="mx-auto mb-10 max-w-4xl">
                {/* Accordion Panel */}
                {randomFaq.map((faqItem) => (
                    <Disclosure key={faqItem.number}>
                        {({ open }) => (
                            <div className="mb-4 border rounded-lg shadow-md overflow-hidden">
                                {/* Question */}
                                <dt className={`group w-full bg-sky-800 text-white py-3 px-4 cursor-pointer ${open && "bg-sky-900"}`}>
                                    <Disclosure.Button className="w-full flex justify-between items-center">
                                        <span className="text-lg sm:text-xl md:text-2xl text-left font-semibold">
                                            {faqItem.question}
                                        </span>
                                        <ChevronDownIcon
                                            className={`w-6 h-6 transform ${open && 'rotate-180'}`}
                                            aria-hidden="true"
                                        />
                                    </Disclosure.Button>
                                </dt>
                                {/* Answer */}
                                <dd>
                                    <Disclosure.Panel>
                                        <div className="p-4 bg-white text-gray-800">
                                            {faqItem.answer}
                                        </div>
                                    </Disclosure.Panel>
                                </dd>
                            </div>
                        )}
                    </Disclosure>
                ))}
            </dl>
        </div>
    );
};

export default Faq1;
