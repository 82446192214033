const language = {
        'insurance': ['insurance', 'ubezpieczenie', 'страхування', 'страхование'],
        'count_price_online': ['Count price ', 'Oblicz składkę ', 'Розрахуйте вартість ', 'Просчитать цену'],
        'contacts': ['contacts', 'kontakty', 'контакти', 'контакты'],
        'agency_intro': ['Assuring lowest prices and best offers', 'Agencja Ubezpieczeniowa, Ochrona na miarę Twoich potrzeb. Zabezpieczamy przyszłość razem', 'Страхова мультиагенція. Пропонуємо найкращі ціни при найкращих умовах', 'Обеспечиваем самые низкие цены и лучшие предложения'],
        'calculate_online': ['Calculate online', 'Oblicz online', 'Розрахуйте онлайн', 'Рассчитать онлайн'],
        'get_lowest_price_offers': ['Get offers with the best price', 'Otrzymaj oferty z najniższymi cenami', 'Отримайте пропозиції з найнижчими цінами', 'Получите предложения с лучшей ценой'],
        'data_sent': ['Your data has been successfully sent. We will contact you in ... ', 'Twoje dane zostały pomyślnie wysłane. Skontaktujemy się z Tobą w ciągu ... ', 'Ваші дані успішно відправлені. Ми зв’яжемося з вами протягом ... ', 'Ваши данные успешно отправлены. Мы свяжемся с вами в течение ... '],
        'clients': ['Clients', 'Klientów', 'Клієнтів', 'Клиенты'],
        'departments': ['Departments', 'Płacówek', 'Відділень', 'Отделения'],
        'years_of_experience': ['Years of experience', 'Lat na rynku', 'Років досвіду', 'Лет опыта'],
        'support_for_foreigners': ['We Offer Comprehensive Support for Foreigners', 'Oferujemy kompleksowe wsparcie dla cudzoziemców', 'Ми пропонуємо комплексну підтримку для іноземців', 'Мы предлагаем комплексную поддержку иностранцам'],
        'temp_residence_card_insurance': ['Temporary Residence Card Insurance', 'Ubezpieczenie na Kartę Pobytu Czasowego', 'Страхування для Карти Побита', 'Страхование на Карту Побыта'],
        'foreign_plated_car_insurance': ['Foreign Plated Car Insurance - OC Graniczne', 'Ubezpieczenie samochodów z zagranicznymi tablicami rejestracyjnymi - OC Graniczne', 'Страхування автомобілів з іноземною реєстрацією - OC Graniczne', 'Страхование автомобилей с иностранными номерами - OC Graniczne'],
        'free_consultation_in_your_language': ['Free Consultation in Your Language', 'Bezpłatna konsultacja w Twoim języku', 'Безкоштовна консультація вашою мовою', 'Бесплатная консультация на вашем языке'],
        'get_started_button': ['Get started today by calculating the best insurance price for your needs using the button below:', 'Rozpocznij już dziś, obliczając najlepszą cenę ubezpieczenia dostosowaną do Twoich potrzeb za pomocą przycisku poniżej:', 'Почніть сьогодні, розраховуючи найкращу ціну страхування для ваших потреб, використовуючи кнопку нижче:', 'Начните сегодня, рассчитав лучшую страховую цену для ваших потреб, используя кнопку'],
        'motor_insurance': ['Motor Insurance', 'Ubezpieczenie komunikacyjne', 'Автострахування', 'Страхование транспортных средств'],
        'oc_insurance': ['Obligatory Civil Liability Insurance', 'obowiązkowe ubezpieczenie', 'Обов’язкове страхування', 'Обязательное страхование'],
        'ac_insurance': ['Autocasco', 'Autocasco', 'Каско', 'Каско'],
        'nnw':['Insurance against the consequences of unfortunate accidents','Ubezpieczenie następstw nieszczęśliwych wypadków','страхування від наслідків нещасних випадків','страхование от последствий несчастных случаев'],
        'calculate_price_button': ['Calculate Price', 'Oblicz cenę', 'Рахуй ціну', 'Рассчитать стоимость'],
        'faq': ['Frequently asked questions', 'Często zadawane pytania', 'Часто задавані питання', 'Часто задаваемые вопросы'],
        'online_offline_support': ['24/7 support to assist you online and offline.', 'Wsparcie 24/7, aby pomóc Ci online i offline.', 'Підтримка 24/7 для допомоги вам онлайн та офлайн.', 'Круглосуточная поддержка для вас онлайн и офлайн.'],
        'six_polish_cities_offices': ['Conveniently located offices in 6 major Polish cities.', 'Dogodnie zlokalizowane biura w 6 głównych miastach Polski.', 'Зручно розташовані офіси в 6 великих містах Польщі.', 'Удобно расположенные офисы в 6 крупных городах Польши.'],
        'best_offers_from_insurance_agencies': ['Best prices from top insurance agencies.', 'Najlepszy oferty od głównych agencji ubezpieczeniowych.', 'Найкращі пропозиції від провідних страхових агентств.', 'Лучшие предложения от ведущих страховых агентств.'],
        'support_for_foreigners_languages': ['Multilingual support for English, Polish, and Ukrainian-speaking customers.', 'Wsparcie wielojęzyczne dla klientów mówiących po angielsku, polsku i ukraińsku.', 'Багатомовна підтримка для клієнтів, які говорять англійською, польською та українською.', 'Многоязычная поддержка для англо-, польско- и украиноязычных клиентов.'],
        'working_hours': ['We work offline and online 24/7.', 'Pracujemy offline i online 24/7.', 'Ми працюємо офлайн і онлайн 24/7.', 'Мы работаем офлайн и онлайн круглосуточно.'],
        'why_we': ['Why we', 'Dlaczego my', 'Чому ми', 'Почему мы'],
        'car_info': ['Car information', 'Dane samochodu', 'Дані авто', 'Данные автомобили'],
        'car_num': ['Car number', 'Numer rejestracyjny', 'Номер авто', 'Номер авто'],
        'model': ['Make', 'Marka', 'Марка', 'Марка' ],
        'official_representatives': ['Official agent of the biggest insurance companies','Współpracujemy z największymi TU', 'Офіційний представник найбільших страхових компаній', 'Официальный представитель крупнейших страховых компаний'],
        'our_licenses': ['Our licenses','Nasze pełnomocnictwa', 'Наші ліцензії', 'Наши лицензии'],
        'year':['Year','Rok','Рік','Год'],
        'modal_text1':['To purchase this product and get a free consultation, call:\n \n','Aby zakupić ten produkt lub skorzystać z bezpłatnej konsultacji, zadzwoń:\n \n','Щоб придбати цей продукт та отримати безкоштовну консультацію, зателефонуйте:\n ','Чтобы приобрести этот продукт и получить бесплатную консультацию, позвоните:\n\n'],
        'modal_text2':['Get a 5% discount by calling now!','Zadzwoń teraz i otrzymaj 5% zniżki','Телефонуйте зараз і отримайте знижку 5%','Звоните сейчас и получите скидку 5%'],
        'car_owner':['Car owner information','Informacje o właścicielu samochodu','Інформація про власника авто','Информация о владельце авто'],
        'accept_terms': ['I accept the Terms and conditions', 'Akceptuję Regulamin', 'Я приймаю Умови та положення', 'Я принимаю Условия и положения'],
        'accept_policy': ['I accept the Privacy Policy', 'Akceptuję Politykę prywatności', 'Я приймаю Політику конфіденційності', 'Я принимаю Политику конфиденциальности'],
        'buy': ['Buy now', 'Kup teraz', 'Придбати', 'Купить']
}


    function capitalizeFirstLetter(str)
{
    return str.charAt(0).toUpperCase() + str.slice(1);
}


export {language, capitalizeFirstLetter};
