import React, {Fragment} from 'react';
import {motion} from "framer-motion";
import {fadeIn} from "../variants";
import CountUp from "react-countup";
import {useInView} from "react-intersection-observer";
import CarImage from '../assets/car.png'


import {language, capitalizeFirstLetter} from "../translation/language";

import Lottie from "lottie-react";
import animation1 from '../assets/animation1.json'
import CarAnimation from '../assets/CarAnimation.json'
import PassportAnimation from '../assets/PassportAnimation.json'


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCar, faCalculator, faCheck} from '@fortawesome/free-solid-svg-icons';
import {faQuestionCircle, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-scroll';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const CarIcon = () => (
    <FontAwesomeIcon icon={faCar}/>
);

const imageStyle = {
    width: '200px', // Adjust the width as needed
    height: '200px', // Adjust the height as needed
};


const settings = {
    dots: true, // Show pagination dots
    infinite: true, // Infinite loop
    speed: 2400, // Transition speed in milliseconds
    slidesToShow: 1, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    autoplay: true, // Auto-play the slider
    autoplaySpeed: 5000, // Auto-play speed in milliseconds (adjust as needed)
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1, // Number of slides to show on smaller screens
                slidesToScroll: 1, // Number of slides to scroll on smaller screens
                infinite: true, // Infinite loop on smaller screens
            },
        },
    ],
};


const Banner = (props) => {
    const [ref, inView] = useInView({
        threshold: 0.3,
    })


    return (
        <div ref={ref}>
            <Slider {...settings} >
                <section className='max-h-[75vh] lg:max-h-[80vh] lg:min-h-[41vh]   items-center' id='home'>
                    <div className="container mx-auto bg-blue-50 rounded-lg pt-2  ">
                        <div
                            className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12   lg:justify-around '>
                            <div className="lg:w-1/2  ">
                                <motion.h1 variants={fadeIn('up', 0.3)} initial="hidden" whileInView={'show'}
                                           viewport={{once: false, amout: 0.7}}
                                           className='text-[55px] font-bold leading-[0.8] lg:text-[68px]'>
                                    NMS <span><br/>Ubezpieczenia</span>

                                    <p className="mt-[20px] mr-[20px] text-[18px]">{language.agency_intro[props.language].toUpperCase()}</p>
                                </motion.h1>

                                <div className="flex justify-start space-x-4 mt-[30px] mb-[30px]">
                                    <Link smooth={true} spy={true} to='features'>
                                        <button
                                            className="w-24 bg-green-500 hover:bg-green-600 text-white py-1 px-2 rounded-md flex items-center space-x-1">
                                            <span className="text-xs whitespace-nowrap">{capitalizeFirstLetter(language.why_we[props.language])}</span>
                                            <FontAwesomeIcon icon={faQuestionCircle} size="sm" className="text-white"/>
                                        </button>
                                    </Link>
                                    <Link smooth={true} spy={true} to='contacts'>
                                        <button
                                            className="w-24 group bg-transparent hover:bg-blue-500 text-blue-500 hover:text-white border border-blue-500 hover:border-blue-600 py-1 px-2 rounded-md flex items-center space-x-1">
                                            <span className="text-xs whitespace-nowrap">{capitalizeFirstLetter(language.contacts[props.language])}</span>
                                            <FontAwesomeIcon icon={faEnvelope} size="sm"
                                                             className="text-blue-500 group-hover:text-white"/>
                                        </button>
                                    </Link>
                                </div>


                                <motion.div
                                    variants={fadeIn('up', 0.4)} initial="hidden" whileInView={'show'}
                                    viewport={{once: false, amout: 0.7}}
                                    className='mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]'>

                                </motion.div>
                                <div className='pt-2 flex gap-x-6 lg:gap-x-10 mb-12'>
                                    <div>
                                        <div className='text-[40px] font-tertiary text-gradient mb-2'>
                                            {inView ?
                                                <CountUp start={250} end={300} duration={5}/> : null

                                            }+
                                        </div>
                                        <div
                                            className='font-primary text-sm tracking-[2px]'>{capitalizeFirstLetter(language.clients[props.language])}</div>
                                    </div>
                                    <div>
                                        <div className='text-[40px] font-tertiary text-gradient mb-2'>
                                            {inView ?
                                                <CountUp start={0} end={5} duration={5}/> : null

                                            }
                                        </div>
                                        <div
                                            className='font-primary text-sm tracking-[2px]'>{capitalizeFirstLetter(language.years_of_experience[props.language])}</div>
                                    </div>
                                    <div>
                                        <div className='text-[40px] font-tertiary text-gradient mb-2'>
                                            {inView ?
                                                <CountUp start={0} end={5} duration={5}/> : null

                                            }
                                        </div>
                                        <div
                                            className='font-primary text-sm tracking-[2px]'>{capitalizeFirstLetter(language.departments[props.language])}</div>
                                    </div>
                                </div>
                            </div>

                            <div className=" hidden relative lg:block lg:w-1/3 pt-12  ">
                                <Lottie animationData={animation1}></Lottie>
                            </div>
                        </div>
                    </div>

                </section>


                <section className="max-h-[50vh] lg:max-h-[80vh] lg:min-h-[41vh] items-center" id="home">
                    <div className="container mx-auto">
                        <div className="flex flex-col lg:flex-row lg:items-center lg:gap-x-12 lg:justify-around h-full">
                            <div className="lg:w-1/2 flex flex-col justify-center">
                                <div className="lg:mt-8 lg:p-6 bg-blue-50 shadow-lg rounded-lg h-full sm:text-[10px]">
                                    <h2 className="text-2xl font-semibold mb-4 text-blue-700">{capitalizeFirstLetter(language.motor_insurance[props.language])}</h2>
                                    <ul className="list-disc pl-6 sm:text-sm lg:text-lg text-gray-700 mb-4">
                                        <li>{capitalizeFirstLetter(language.oc_insurance[props.language])}<a className="text-blue-500 font-bold"> OC</a></li>
                                        <li>{capitalizeFirstLetter(language.ac_insurance[props.language])}<a className="text-blue-500 font-bold"> AC</a></li>
                                        <li>{capitalizeFirstLetter(language.nnw[props.language])}<a className="text-blue-500 font-bold"> NNW</a></li>
                                    </ul>

                                    {/*<ul className="list-disc pl-6 sm:text-sm lg:text-lg text-gray-700 mb-4">*/}
                                    {/*    <li>{capitalizeFirstLetter(language.oc_insurance[props.language])}<a className="text-blue-500 font-bold"> OC</a> - <span className="text-green-500 font-bold border border-green-500 px-2 py-1 rounded">od 350zł</span></li>*/}
                                    {/*    <li>{capitalizeFirstLetter(language.ac_insurance[props.language])}<a className="text-blue-500 font-bold"> AC</a> - <span className="text-green-500 font-bold border border-green-500 px-2 py-1 rounded">od 480zł</span></li>*/}
                                    {/*    <li>{capitalizeFirstLetter(language.nnw[props.language])}<a className="text-blue-500 font-bold"> NNW</a> - <span className="text-green-500 font-bold border border-green-500 px-2 py-1 rounded">25zł</span></li>*/}
                                    {/*</ul>*/}


                                    <div
                                        className="relative flex flex-row gap-x-1 sm:justify-around lg:justify-start items-center">
                                        <button
                                            className="w-auto sm:w-30 bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium text-white py-2 px-4 rounded-md flex items-center space-x-2 animate-bounce lg:self-start">
                                            <Link smooth={true} spy={true} to='calculator'>
                                                <span
                                                    className="text-sm sm:text-base">{capitalizeFirstLetter(language.calculate_price_button[props.language])}</span>
                                            </Link>
                                            <FontAwesomeIcon icon={faCalculator} style={{fontSize: "2em"}}
                                                             className="text-white"/>
                                        </button>
                                        <div className="hidden absolute lg:block lg:w-1/3"
                                             style={{right: 0, position: 'absolute'}}>
                                            <Lottie animationData={CarAnimation}></Lottie>
                                        </div>
                                        <div className="sm:block lg:hidden lg:block lg:w-1/2 h-full">
                                            <img src={CarImage} className="w-[20vh] max-w-full" alt="Car"/>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="hidden lg:block lg:w-1/2 h-full">
                                {/*<img src={CarBackground} className="w-1/3 md:w-1/2 lg:w-[100%] absolute top-0 right-0"/>*/}
                                <img src={CarImage} fill alt="Car"/>
                            </div>
                        </div>
                    </div>
                </section>


                <section className=" lg:max-h-[80vh] lg:min-h-[41vh] items-center" id="home">
                    <div className="container mx-auto">
                        <div className="flex flex-col lg:flex-row lg:items-center lg:gap-x-12 lg:justify-around h-full">
                            <div className="lg:w-1/2 flex flex-col justify-center">
                                <div className="lg:mt-8 lg:p-6 bg-blue-50 shadow-lg rounded-lg h-full sm:text-[10px]">
                                    <h2 className="text-2xl font-semibold mb-4 text-blue-700">{capitalizeFirstLetter(language.support_for_foreigners[props.language])}</h2>
                                    <ul className="pl-6 sm:text-sm lg:text-lg text-gray-700 mb-4 list-none animated-list">
                                        <li className="flex items-center mb-2">
                                            <FontAwesomeIcon icon={faCheck} style={{fontSize: "1.5em"}}
                                                             className="text-green-500 mr-2"/>
                                            {capitalizeFirstLetter(language.temp_residence_card_insurance[props.language])}
                                        </li>
                                        <li className="flex items-center mb-2">
                                            <FontAwesomeIcon icon={faCheck} style={{fontSize: "1.5em"}}
                                                             className="text-green-500 mr-2"/>
                                            {capitalizeFirstLetter(language.foreign_plated_car_insurance[props.language])}
                                        </li>
                                        <li className="flex items-center">
                                            <FontAwesomeIcon icon={faCheck} style={{fontSize: "1.5em"}}
                                                             className="text-green-500 mr-2"/>
                                            {capitalizeFirstLetter(language.free_consultation_in_your_language[props.language])}
                                        </li>
                                    </ul>
                                    <div className="flex">
                                        <button
                                            className="bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium text-white py-2 px-4 rounded-md flex items-center space-x-2 animate-bounce">
                                            <Link smooth={true} spy={true} to='calculator'>
                                                <span
                                                    className="text-sm sm:text-base">{capitalizeFirstLetter(language.calculate_price_button[props.language])}</span>
                                            </Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="hidden relative lg:block lg:w-1/3 pt-12">
                                <Lottie animationData={PassportAnimation}></Lottie>
                            </div>
                        </div>
                    </div>
                </section>


            </Slider>
        </div>

    )
        ;
};

export default Banner;
