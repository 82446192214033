import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import HDI from '../assets/HDI.svg'
import ErgoHestia from '../assets/Ergo Hestia.svg'
import TUW from '../assets/TUW.svg'
import PZU from '../assets/PZU.svg'
import InterRisk from '../assets/InterRisk.svg'
import Balcia from '../assets/balcia.svg'
import Warta from '../assets/Warta.svg'

const ImageSlider = () => {
    const settings = {
        dots: true, // Show pagination dots
        infinite: true, // Infinite loop
        speed: 500, // Transition speed in milliseconds
        slidesToShow: 5, // Number of slides to show at a time
        slidesToScroll: 1, // Number of slides to scroll at a time
        autoplay: true, // Auto-play the slider
        autoplaySpeed: 2000, // Auto-play speed in milliseconds (adjust as needed)
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4, // Number of slides to show on smaller screens
                    slidesToScroll: 3, // Number of slides to scroll on smaller screens
                    infinite: true, // Infinite loop on smaller screens
                },
            },
            {
                breakpoint: 460,
                settings: {
                    slidesToShow: 4, // Number of slides to show on smaller screens
                    slidesToScroll: 3, // Number of slides to scroll on smaller screens
                    infinite: true, // Infinite loop on smaller screens
                },
            },
        ],
    };

    return (
        <div className="bg-white  max-w-3xl mx-auto mt-8">
            <Slider {...settings}>
                <div className="px-4 ">
                    <img src={PZU} alt="PZU" className="w-auto h-auto" />
                </div>
                <div className="px-4 ">
                    <img src={HDI} alt="HDI" className="w-auto h-auto" />
                </div>
                <div className="px-4 ">
                    <img src={TUW} alt="TUW" className="w-auto h-auto" />
                </div>
                <div className="px-4 ">
                    <img src={ErgoHestia} alt="Ergo Hestia" className="w-auto h-auto" />
                </div>
                <div className="px-4 ">
                    <img src={InterRisk} alt="Ergo Hestia" className="w-auto h-auto" />
                </div>
                <div className="px-4 ">
                    <img src={Balcia} alt="Ergo Hestia" className="w-auto h-auto" />
                </div>
                <div className="px-4 ">
                    <img src={Warta} alt="Ergo Hestia" className="w-auto h-auto" />
                </div>
                {/* Add more images here */}
            </Slider>
        </div>
    );
};

export default ImageSlider;


