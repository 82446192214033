import React from 'react';
import {
    Phone,
    LocationOn,
    Telegram,
    Facebook,
    Instagram,
    Email,
} from '@mui/icons-material';
import {capitalizeFirstLetter, language} from "../translation/language";

const Contacts = (props) => {
    const mainOffice = {
        title: 'Main Office',
        phone: '+48-571-850-172',
        address: 'ul. Erazma Ciołka 17 lok. 115',
        telegram: 'nmsubezpieczenia',
        whatsapp: '+1 (123) 456-7890',
        email: 'ubezpieczenia@nmskorpo.pl',
        instagram: 'ubezpieczenia.poland'
    };

    return (
        <div
            className="contacts-container p-4 md:p-8 flex flex-col md:flex-row gap-4 items-center justify-around bg-gray-100 rounded-lg shadow-md"
            id='contact'>
            <div className="contact-info max-w-md">
                <h2 className="text-3xl text-blue-600 mb-4 md:mb-8 font-semibold">{capitalizeFirstLetter(language.contacts[props.language])}</h2>
                <div className="max-w-md space-y-4">
                    <div className="flex items-center">
                        <Phone className="w-6 h-6 text-blue-500"/>
                        <p className="text-lg md:text-xl ml-2">
                            Phone: <a href={`tel:${mainOffice.phone}`}>{mainOffice.phone}</a>
                        </p>
                    </div>

                    <div className="flex items-center">
                        <LocationOn className="w-6 h-6 text-blue-500"/>
                        <p className="text-lg md:text-xl ml-2">Address: {mainOffice.address}</p>
                    </div>

                    <div className="flex items-center">
                        <Telegram className="w-6 h-6 text-blue-500"/>
                        <a
                            href={`https://t.me/${mainOffice.telegram}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-lg md:text-xl ml-2 text-blue-500 hover:underline hover:text-blue-700"
                        >
                            Telegram: {mainOffice.telegram}
                        </a>
                    </div>

                    <div className="flex items-center">
                        <Email className="w-6 h-6 text-blue-500"/>
                        <a
                            href={`mailto:${mainOffice.email}`}
                            className="text-lg md:text-xl ml-2 text-blue-500 hover:underline hover:text-blue-700"
                        >
                            Email: {mainOffice.email}
                        </a>
                    </div>

                    <div className="flex items-center">
                        <Instagram className="w-6 h-6 text-blue-500"/>
                        <a
                            href={`mailto:${mainOffice.instagram}`}
                            className="text-lg md:text-xl ml-2 text-blue-500 hover:underline hover:text-blue-700"
                        >
                            {mainOffice.instagram}
                        </a>
                    </div>

                </div>
                {/*<div className="social-media mt-4 md:mt-8 space-x-4">*/}
                {/*    <a href="https://www.facebook.com/yourpage" target="_blank" rel="noopener noreferrer">*/}
                {/*        <Facebook className="w-8 h-8 text-blue-600 hover:text-blue-700"/>*/}
                {/*    </a>*/}
                {/*    <a href="https://www.instagram.com/yourpage" target="_blank" rel="noopener noreferrer">*/}
                {/*        <Instagram className="w-8 h-8 text-blue-600 hover:text-blue-700"/>*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>
            <div className="map-container mt-4 md:mt-0">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9772.088105297016!2d20.944770160852052!3d52.24298357304748!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecb713b3c6327%3A0x865a395d60e9c326!2sNMS%20Ubezpieczenia!5e0!3m2!1suk!2spl!4v1699292896311!5m2!1suk!2spl"
                    width="450"
                    height="450"
                    style={{ border: 0, borderRadius: '8px' }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        </div>
    );
};

export default Contacts;
