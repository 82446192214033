import React, {Fragment} from "react";
import NMSNew from '../assets/NMSNew.png'


const Footer = () => {
    return (
        <Fragment>
            <div className="bg-site  py-3">
                <div className="container mx-auto p-6">
                    <div className="flex flex-col md:flex-row">
                        <div className="md:w-1/2 p-4 text-white">
                            <h2 className="text-4xl font-semibold mb-4 text-white-500">The NMS Group Company</h2>
                            <p className="text-gray-300">
                                NMS UBEZPIECZENIA SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ< br/>
                                All Rights Reserved &copy; 2024
                            </p>
                            <div className="mt-4">
                                <a className="text-blue-400 underline text-lg" href="/">
                                    Privacy Policy
                                </a>
                                <span className="text-gray-400 mx-2">|</span>
                                <a className="text-blue-400 underline text-lg" href="/">
                                    Terms and Conditions
                                </a>
                            </div>
                        </div>
                        <div className="md:w-1/2 mt-4 md:mt-0 flex justify-center">
                            <div className="aspect-w-2 aspect-h-1 rounded-md overflow-hidden bg-white p-4">
                                <img className="object-cover mt-6 " src={NMSNew} alt='' />
                            </div>
                        </div>
                    </div>
                </div>


                {/*<div className="container mx-auto p-6">*/}
                {/*    <div className="flex flex-col md:flex-row">*/}
                {/*        <div className="md:w-1/2 flex justify-center">*/}
                {/*            <img className="w-[280px] h-[280px] rounded-full" src={Poland} alt=''/>*/}
                {/*        </div>*/}
                {/*        <div className="md:w-1/2 p-4 text-white">*/}
                {/*            <h2 className="text-3xl font-semibold mb-4">About Our Insurance Agency</h2>*/}
                {/*            <p className="text-gray-300">*/}
                {/*                We are a trusted insurance agency with a mission to provide comprehensive coverage and*/}
                {/*                peace of*/}
                {/*                mind to our clients. With over 10 years of experience in the insurance industry, we*/}
                {/*                specialize*/}
                {/*                in a wide range of insurance products, including home, auto, health, and life insurance.*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<BannerSlider />*/}

            </div>
        </Fragment>


    )
}
export default Footer;