import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import ExampleWrapper from "../components/ExampleWrapper";
import Banner from "../components/Banner";
import InsuranceCards from "../components/InsuranceCards";
import CountForm from "../components/CountForm";
import Faq1 from "../components/Faq1";
import Features3 from "../components/Features3";
import Certificates from "../components/Certificates";
import Contacts from "../components/Contacts";
import Footer from "../components/Footer";
import NavSmall from "../components/NavSmall";
import Nav from "../components/Nav";

const HomePage = () => {
    const [screenSize, setScreenSize] = useState('');
    const [activeLanguage, SetActiveLanguage] = useState(0);

    const [isOpen, setIsOpen] = useState(false);
    const triggerSetIsOpen = (value) => {
        setIsOpen(value);
    };


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setScreenSize('small');
            } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
                setScreenSize('medium');
            } else {
                setScreenSize('large');
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    return (
        <div className=' bg-no-repeat bg-cover overflow-hidden bg-white'>
            <Header setLanguage={SetActiveLanguage} language={activeLanguage}/>
            <ExampleWrapper language={activeLanguage} isOpen={isOpen} setIsOpen={triggerSetIsOpen}/>
            <Banner language={activeLanguage}/>
            <div className="bg-white mt-[28px]  ">
                <InsuranceCards  setIsOpen={triggerSetIsOpen} language={activeLanguage}/>
            </div>
            <CountForm language={activeLanguage}/>
            <Faq1 language={activeLanguage}/>
            <Features3 language={activeLanguage}/>
            <Certificates language={activeLanguage}/>
            <Contacts language={activeLanguage}/>
            <Footer language={activeLanguage}/>

            {/*<Modal language={activeLanguage}/>*/}
            {/*<IndexPage />*/}

            {screenSize === 'small' &&  <NavSmall/>}
            {screenSize === 'medium' && <NavSmall/>}
            {screenSize === 'large' && <Nav/>}
            {/*/!*<About/>*!/*/}
            {/*<Services/>*/}
            {/*<Work/>*/}
            {/*<Contact/>*/}
            {/*<ContactUs/>*/}
            {/*<div className='h-[4000px]'></div>*/}
        </div>
    );
}
export default HomePage;