import React from 'react';
import {BiHomeAlt,BiCalculator, BiPhone} from 'react-icons/bi';
import {BsQuestion} from 'react-icons/bs';

import {Link} from 'react-scroll';

const NavSmall = () => {
    return (
        <nav>
            <div className='fixed bottom-2 lg:bottom-8 w-full overflow-hidden z-50'>
                <div className='container mx-auto'>
                    <div className='w-full bg-black/20 h-[96px] backdrop-blur-2x1 rounded-full max-w-[460px] mx-auto px-5 flex
                justify-between items-center text-2x1 text-white/50'>
                        <Link activeClass='active' smooth={true} spy={true} offset={-200} to='home' className='cursor-pointer w-[50px] h-[50px] flex items-center justify-center my-2'>
                            <BiHomeAlt style={{ fontSize: '1.5rem' }} />
                        </Link>
                        <Link activeClass='active' offset={-400}  smooth={true} spy={true} to='calculator' className='cursor-pointer w-[50px] h-[50px] flex items-center justify-center my-2'>
                            <BiCalculator style={{ fontSize: '1.5rem' }} />
                        </Link>
                        <Link activeClass='active' offset={-100}    smooth={true} spy={true} to='faq' className='cursor-pointer w-[50px] h-[50px] flex items-center justify-center my-2'>
                            <BsQuestion style={{ fontSize: '1.5rem' }} />
                        </Link>
                        <Link activeClass='active' offset={-300} smooth={true} spy={true} to='contact' className='cursor-pointer w-[50px] h-[50px] flex items-center justify-center my-2'>
                            <BiPhone style={{ fontSize: '1.5rem' }} />
                        </Link>
                    </div>
                </div>
            </div>
        </nav>
    )
};

export default NavSmall;