import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faHistory, faBirthdayCake, faCar, faHome, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";


const Questions = () => {
    return (
        // <div className="bg-gradient-to-r from-blue-400 to-blue-200 bg-cover overflow-hidden card-white h-screen flex flex-col justify-center items-center">
        // <div className=" bg-gradient-to-b from-sky-200 to-indigo-400  overflow-hidde h-screen ">
        <div className=" bg-white overflow-hidde h-screen ">
            <div className="container mx-auto text-center">
                <div className="mt-8">
                    <button className="bg-blue-500 hover:bg-blue-600 focus:outline-none px-6 py-3 rounded-full text-white flex items-center">
                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                        To Main Page
                    </button>
                </div>

                <div className="mt-8 text-white">
                    <div className="bg-gradient-to-b from-rose-100 to-blue-200 px-8 py-6 rounded-lg shadow-md">
                        <p className="text-3xl font-bold mb-4 text-black">Suma gwarancyjna OC</p>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="text-xl text-black">
                                <p className="font-semibold mb-2">Szkody osobowe</p>
                                <p className="text-2xl font-bold">5 210 000 euro</p>
                            </div>
                            <div className="text-xl text-black">
                                <p className="font-semibold mb-2">Szkody majątkowe</p>
                                <p className="text-2xl font-bold">1 050 000 euro</p>
                            </div>
                        </div>
                        <button className="bg-blue-700 text-white py-2 px-12 rounded-md">
                            Calculate Price
                        </button>
                        <p className="text-3xl font-bold mb-4 text-black mt-12">Co wpływa na cenę ubezpieczenia OC?</p>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            {/* History Grid Item */}
                            <div className="bg-transparent p-4 text-black rounded border border-black text-center relative">
                                <FontAwesomeIcon icon={faHistory} size="3x" className="mb-2 text-blue-500 shadow-sm" />
                                <p className="text-lg font-bold">History</p>
                            </div>

                            {/* Age Grid Item */}
                            <div className="bg-transparent p-4 text-black rounded border border-black text-center relative">
                                <FontAwesomeIcon icon={faBirthdayCake} size="3x" className="mb-2 text-green-500 shadow-sm" />
                                <p className="text-lg font-bold">Age</p>
                            </div>

                            {/* Vehicle Grid Item */}
                            <div className="bg-transparent p-4 text-black rounded border border-black text-center relative">
                                <FontAwesomeIcon icon={faCar} size="3x" className="mb-2 text-purple-500 shadow-sm" />
                                <p className="text-lg font-bold">Vehicle</p>
                            </div>

                            {/* Place of Living Grid Item */}
                            <div className="bg-transparent p-4 text-black rounded border border-black text-center relative">
                                <FontAwesomeIcon icon={faHome} size="3x" className="mb-2 text-yellow-500 shadow-sm"  />
                                <p className="text-lg font-bold">Place of Living</p>
                            </div>
                        </div>
                        <div className="bg-white p-8 rounded-3xl shadow-md w-1/2 mt-12">
                            <div className="flex-col items-center text-left">
                                <div className="flex items-center">
                                    <div className="border-black border-transparent rounded-full p-2 mr-4 text-red-600">
                                        <FontAwesomeIcon icon={faPhoneAlt} size="3x" className="text-red-600" />
                                    </div>
                                    <p className="text-4xl sm:text-5xl font-bold tracking-wider text-black ">+48 571 850 172</p>
                                </div>
                                <div className="text-center">
                                    <p className="text-4xl sm:text-5xl font-bold tracking-wider text-black ">Call Us</p>
                                    <p className="text-gray-500">Reach out to us for assistance</p>
                                </div>
                            </div>
                        </div>



                        {/*<div className="bg-white p-4 rounded-lg shadow-md w-1/2">*/}
                        {/*    <div className="flex justify-end mb-2">*/}
                        {/*        <div className="bg-gradient-to-r from-blue-600 to-blue-900 text-white py-2 px-4 rounded-lg">*/}
                        {/*            Your message here*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*    <div className="flex mb-2">*/}
                        {/*        <div className="bg-gray-200 py-2 px-4 rounded-lg">*/}
                        {/*            Received message here*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*    <div className="flex justify-end">*/}
                        {/*        <div className="bg-gradient-to-r from-blue-600 to-blue-900 text-white py-2 px-4 rounded-lg">*/}
                        {/*            Another message from you*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*    /!* Fake Input Field *!/*/}
                        {/*    <div className="flex mt-4">*/}
                        {/*        <input*/}
                        {/*            type="text"*/}
                        {/*            className="flex-grow bg-gray-200 py-2 px-4 rounded-full focus:outline-none"*/}
                        {/*            placeholder="Type your message..."*/}
                        {/*        />*/}
                        {/*        <button className="ml-2 bg-gradient-to-r from-blue-600 to-blue-900 text-white py-2 px-4 rounded-full">*/}
                        {/*            Send*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}


                    </div>

                    <div className="bg-gradient-to-b from-yellow-200 to-orange-400 h-screen"></div>
                    <div className="bg-gradient-to-b from-sky-200 to-indigo-400 h-screen"></div>

                </div>



            </div>
            {/* Placeholder for content or adjust height accordingly */}
            {/*<div className="h-[400px]"></div>*/}
        </div>
    );
};

export default Questions;
