import certeh from '../assets/certeh.jpg'
import certcomp from '../assets/certcomp.jpg'
import certtuw from '../assets/certtuw.jpg'
import certpzu from '../assets/certpzu.jpg'
import {language} from "../translation/language";

const Certificates = (props) => {
  return (
      <div className="container mx-auto">
          <h2 className="text-center text-4xl sm:text-5xl text-sky-800 font-bold tracking-wider mt-12">{language.official_representatives[props.language]}</h2>
          <h3 className="text-center sm:text-3xl text-black-800 font-bold tracking-wider mb-14">{language.our_licenses[props.language]}</h3>
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-3 mb-8">
              <a href={certeh} target="_blank" rel="noopener noreferrer">
                  <img className="w-full h-full transition-transform transform hover:scale-105" style={{ boxShadow: "0 0 10px rgba(0, 0, 255, 0.5)" }} src={certeh} alt="Certificate Image" />
              </a>
              <a href={certcomp} target="_blank" rel="noopener noreferrer">
                  <img className="w-full h-full transition-transform transform hover:scale-105" style={{ boxShadow: "0 0 10px rgba(0, 0, 255, 0.5)" }} src={certcomp} alt="Certificate Image" />
              </a>
              <a href={certtuw} target="_blank" rel="noopener noreferrer">
                  <img className="w-full h-full transition-transform transform hover:scale-105" style={{ boxShadow: "0 0 10px rgba(0, 0, 255, 0.5)" }} src={certtuw} alt="Certificate Image" />
              </a>
              <a href={certpzu} target="_blank" rel="noopener noreferrer">
                  <img className="w-full h-full transition-transform transform hover:scale-105" style={{ boxShadow: "0 0 10px rgba(0, 0, 255, 0.5)" }} src={certpzu} alt="Certificate Image" />
              </a>
          </div>
      </div>
  )
}
export default Certificates;