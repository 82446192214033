import React from 'react';
import {useState, useEffect, Fragment} from "react";

// components
import Banner from './components/Banner';
import Header from './components/Header';
import Footer from './components/Footer';
import Faq1 from './components/Faq1';
import Nav from './components/Nav';
import NavSmall from "./components/NavSmall";
import InsuranceCards from "./components/InsuranceCards";
import CountForm from "./components/CountForm";
import Features3 from "./components/Features3";
import Contacts from "./components/Contacts";
import Certificates from "./components/Certificates";
import Modal from "./components/Modal";
import IndexPage from "./components/IndexPage";
import ExampleWrapper from "./components/ExampleWrapper";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import HomePage from "./pages/HomePage"
import Questions from "./pages/Questions"

const router = createBrowserRouter([
    {
        path:'/',
        element:<HomePage/>,
    },
    {path:"/questions", element:<Questions/>}
])

const App = () => {
    return(
        <>
            <RouterProvider router={router}/>
        </>
    )
};

export default App;
