import React from "react";
import emailjs from '@emailjs/browser';

import {useState} from "react";
import {useForm} from "react-hook-form";

import Lottie from "lottie-react";
import calculatorEdit from '../assets/calculatorEdit.json'


import {language, capitalizeFirstLetter} from "../translation/language";
import ShiftingCountdown from "./CountDown";

const MAX_STEPS = 3

const CountForm = (props) => {
    const [selectedValue, setSelectedValue] = useState('1');

    const [formStep, setFormStep] = useState(0);
    const {watch, register, handleSubmit, formState: {errors, isValid}} = useForm(
        {
            mode: "all",
            defaultValues: {}
        }
    )

    const completeFormStep = () => {
        setFormStep(cur => cur + 1)
    }

    const renderButton = () => {
        if (formStep > 2) {
            return undefined
        } else if (formStep === 2) {
            return (
                <button
                    disabled={!isValid}
                    // onClick={completeFormStep}
                    type="submit"
                    className="mt-6 bg-green-600 text-white rounded px-8 py-6 w-full disabled:bg-gray-400 disabled:cursor-not-allowed"
                >
                    Create Account
                </button>
            )
        } else {
            return (
                <button
                    disabled={!isValid}
                    onClick={completeFormStep}
                    type="button"
                    className="mt-6 bg-green-600 text-white rounded px-8 py-6 w-full disabled:bg-gray-400 disabled:cursor-not-allowed"
                >
                    Next Step
                </button>
            )
        }
    }


    const sendEmail = (emailData) => {
        emailjs.send('service_i516rec', 'template_s61nr58', emailData, 'dNX9axWrN-5aWkmLJ')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };


    const submitForm = (values) => {
        sendEmail(values)
        window.alert(JSON.stringify(values, null, 2))
        completeFormStep()
    }

    const goToPreviousStep = () => {
        setFormStep(cur => cur - 1)
    }

    const handleSelectChange = (event) => {
        const selectedOption = event.target.value;
        setSelectedValue(selectedOption);
    };


    return (
        <div className="bg-calcBg  min-h-[100vh] calculator-div flex flex-col lg:flex-row items-start antialiased relative" id="calculator">

            <div className="mx-auto z-10 text-blue-400 mt-8 text-center">
                <h1 className="text-5xl text-white font-extrabold">
                    {capitalizeFirstLetter(language.count_price_online[props.language])}
                    <span className="text-black font-bold"> online</span>
                </h1>
                {/*<p className="text-green-500 mt-2 text-lg">*/}
                {/*    {capitalizeFirstLetter(language.get_lowest_price_offers[props.language])}*/}
                {/*</p>*/}
                <div>
                    <Lottie animationData={calculatorEdit} />
                </div>
            </div>
            <div className="max-w-xl text-black w-full mt-4 mb-24 rounded-lg shadow-2xl bg-white mx-auto overflow-hidden z-10">
                <div className="px-16 py-10">
                    <div className="flex font-semibold text-3xl mb-8  justify-center  items-center mb-2">
                        <select value={selectedValue} onChange={handleSelectChange}>
                            <option value="1">Komunikacja</option>
                            {/*<option value="2">Dom</option>*/}
                            {/*<option value="3">Zdrowie</option>*/}
                        </select>
                    </div>
                    <form onSubmit={handleSubmit(submitForm)}>


                        {formStep < MAX_STEPS && <div className="flex items-center mb-2">
                            {
                                formStep > 0 && (
                                    <button onClick={goToPreviousStep} type="button">
                                        <svg className="w-6 text-gray-400 hover:text-blue-600 mr-2"
                                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             stroke-width="1.5"
                                             stroke="currentColor" className="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"/>
                                        </svg>
                                    </button>
                                )
                            }


                            <p className="text-sm text-gray-700 ">Step {formStep + 1} of {MAX_STEPS}</p>
                        </div>}
                        {formStep >= 0 &&
                        (<section className={formStep === 0 ? "block" : "hidden"}>
                            <h2 className="font-semibold text-3xl mb-8">
                                {capitalizeFirstLetter(language.car_info[props.language])}
                            </h2>
                            <label htmlFor="username">{capitalizeFirstLetter(language.car_num[props.language])}</label>
                            <input
                                type="text"
                                id="carNumber"
                                name="carNumber"
                                className="text-input"
                                {...register("carNumber", {required: {value: true, message: "Enter username"}})}
                            />
                            <label htmlFor="username">Year</label>
                            <input
                                type="text"
                                id="year"
                                name="year"
                                className="text-input"
                                {...register("year", {required: {value: true, message: "Enter username"}})}
                            />
                            <label htmlFor="username">{capitalizeFirstLetter(language.model[props.language])}</label>
                            <input
                                type="text"
                                id="model"
                                name="model"
                                className="text-input"
                                {...register("model", {required: {value: true, message: "Enter car model"}})}
                            />
                            {errors.username && <p className="text-red-600 text-sm mt-2">{errors.username.message}</p>}
                        </section>)
                        }

                        {formStep >= 1 &&
                        (<section className={formStep === 1 ? "block" : "hidden"}>
                            <h2 className="font-semibold text-3xl mb-8">
                                {capitalizeFirstLetter(language.car_owner[props.language])}
                            </h2>
                            <label htmlFor="address">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                className="text-input"
                                {...register("name", {required: {value: true, message: "Enter adress"}})}

                            />
                            <label htmlFor="address">Surname</label>
                            <input
                                type="text"
                                id="surname"
                                name="surname"
                                className="text-input"
                                {...register("surname", {required: {value: true, message: "Enter adress"}})}

                            />
                            <label htmlFor="address">Adress</label>
                            <input
                                type="text"
                                id="adress"
                                name="adress"
                                className="text-input"
                                {...register("adress", {required: {value: true, message: "Enter adress"}})}

                            />
                            {errors.address && <p className="text-red-600 text-sm mt-2">{errors.address.message}</p>}
                        </section>)
                        }

                        {formStep >= 2 &&
                        (<section className={formStep === 2 ? "block" : "hidden"}>
                            {/*<h2 className="font-semibold text-3xl mb-8">Legal Information</h2>*/}
                            <div className="block mt-6">
                                <input
                                    name="toc"
                                    className="p-3 text-green-600 rounded mr-3 border-2 border-gray-300 ring-0 focus:ring-0 focus:ring-offset-0 focus:border-0 cursor-pointer"
                                    type="checkbox"
                                    {...register("toc", {required: true})}
                                />
                                <span>
                                     {/*I accept the{" "}*/}
                                    <a className="text-blue-400 underline" href="/">
                                        {capitalizeFirstLetter(language.accept_terms[props.language])}
                                    </a>
                                    .
                                </span>
                            </div>
                            <div className="block mt-6">
                                <input
                                    name="pp"
                                    className="p-3 text-green-600 rounded mr-3 border-2 border-gray-300 ring-0 focus:ring-0 focus:ring-offset-0 focus:border-0 cursor-pointer"
                                    type="checkbox"
                                    {...register("pp", {required: true})}

                                />
                                <span>
                                    {/*I accept the{" "}*/}
                                    <a className="text-blue-400 underline" href="/">
                                        {capitalizeFirstLetter(language.accept_policy[props.language])}
                                    </a>
                                    .
                                </span>
                            </div>

                        </section>)
                        }


                        {formStep === 3 &&
                        (<section>
                            <h2 className="font-semibold text-3xl mb-8">{capitalizeFirstLetter(language.data_sent[props.language])}</h2>
                            <ShiftingCountdown/>


                        </section>)
                        }

                        {renderButton()}
                        {/*<pre>*/}
                        {/*    {JSON.stringify(watch(), null, 2)}*/}
                        {/*</pre>*/}
                    </form>



                </div>
            </div>
        </div>
    )
}

export default CountForm;
