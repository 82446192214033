import React from 'react';
import {useState, useEffect, useRef} from "react";
import {language, capitalizeFirstLetter} from "../translation/language";
import ReactCountryFlag from "react-country-flag"
import {Link} from 'react-scroll';
import NMSUbezpieczeniaNew from '../assets/NMSUbezpieczeniaNew.png'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons";


const Header = (props) => {

    const [isOpen, setIsOpen] = useState(false)
    const [activeLanguage, setActiveLanguage] = useState('PL')


    if (activeLanguage === "EN") {
        props.setLanguage(0);
    } else if (activeLanguage === "PL") {
        props.setLanguage(1);
    } else if (activeLanguage === "UA") {
        props.setLanguage(2);
    } else if (activeLanguage === "RU\u200B") {
        props.setLanguage(3);
    } else {
        props.setLanguage(0)
    }

    const menuRef = useRef();

    useEffect(() => {
        let handler = (e) => {
            if (!menuRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handler);
    })

    return (
        <div className='p-4'>
            <div className='container mx-auto'>
                <div className='flex justify-between items-center'>
                    <a>
                        <img className="w-14 h-auto" src={NMSUbezpieczeniaNew} alt='NMS Ubezpieczenia'/>
                    </a>


                    <div className="flex flex-row items-center">
                        <div ref={menuRef} className="relative flex flex-col items-center  min-w-[150px] rounded-lg">
                            <button onClick={() => setIsOpen((prev) => !prev)} className="bg-blue-400 p-2 width-full flex items-center justify-between font-bold rounded-lg
        active:border-white duration-300 active:text-white
        text-sm tracking-wider border-2 border-transparent">
                                <ReactCountryFlag className="mr-2" countryCode={activeLanguage} svg/>
                                <h3 className="font-bold text-sm">{activeLanguage}</h3>
                                {isOpen && (
                                    <div
                                        className="bg-blue-400 z-10 absolute top-14 flex flex-col items-start rounded-lg p-1 w-full">
                                        <div onClick={() => setActiveLanguage("PL")}
                                             className="flex p-2 w-full justify-start hover:bg-blue-300 cursor-pointer rounded-r-lg border-l-transparent hover:border-l-white border-l-2">
                                            <ReactCountryFlag className="mr-2" countryCode="PL" svg/>
                                            <h3 className="font-bold text-sm">Polski</h3>
                                        </div>
                                        <div onClick={() => setActiveLanguage("UA")}
                                             className="flex p-2 w-full  justify-start hover:bg-blue-300 cursor-pointer rounded-r-lg border-l-transparent hover:border-l-white border-l-2">
                                            <ReactCountryFlag className="mr-2" countryCode="UA" svg/>
                                            <h3 className="font-bold text-sm">Україньська</h3>
                                        </div>
                                        <div onClick={() => setActiveLanguage("US")}
                                             className="flex p-2 w-full  justify-start hover:bg-blue-300 cursor-pointer rounded-r-lg border-l-transparent hover:border-l-white border-l-2">
                                            <ReactCountryFlag className="mr-2" countryCode="US" svg/>
                                            <h3 className="font-bold text-sm">English</h3>
                                        </div>
                                        <div onClick={() => setActiveLanguage("RU\u200B")}
                                             className="flex p-2 w-full  justify-start hover:bg-blue-300 cursor-pointer rounded-r-lg border-l-transparent hover:border-l-white border-l-2">
                                            {/*<ReactCountryFlag className="mr-2" countryCode="US" svg/>*/}
                                            <h3 className="font-bold text-sm">Русский</h3>
                                        </div>
                                    </div>
                                )}
                            </button>
                        </div>
                        <Link smooth={true} spy={true} to='contact'>
                            <button className='btn btn-sm'>
                                <FontAwesomeIcon icon={faPhone} className="w-4 h-4 mr-2 text-white"/>
                                {capitalizeFirstLetter(language.contacts[props.language])}
                            </button>
                        </Link>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
