import React from 'react';
import { BiHomeAlt, BiCalculator, BiPhone, BiQuestionMark } from 'react-icons/bi';
import { BsQuestion } from 'react-icons/bs';
import { Link } from 'react-scroll';

const Nav = () => {
    return (
        <nav>
            <div className='sm:fixed bottom-2 left-0 h-full z-50 fixed top-20 left-0 h-full z-50'>
                <div className='bg-black/20 backdrop-blur-2x1 rounded-r-full w-[60px] px-3 py-5 flex flex-col items-center text-white/50'>
                    <Link activeClass='active' smooth={true} spy={true} offset={-200} to='home' className='cursor-pointer w-[50px] h-[50px] flex items-center justify-center my-2'>
                        <BiHomeAlt style={{ fontSize: '1.5rem' }} />
                    </Link>
                    <Link activeClass='active' offset={-400}  smooth={true} spy={true} to='calculator' className='cursor-pointer w-[50px] h-[50px] flex items-center justify-center my-2'>
                        <BiCalculator style={{ fontSize: '1.5rem' }} />
                    </Link>
                    <Link activeClass='active' offset={-100}    smooth={true} spy={true} to='faq' className='cursor-pointer w-[50px] h-[50px] flex items-center justify-center my-2'>
                        <BsQuestion style={{ fontSize: '1.5rem' }} />
                    </Link>
                    <Link activeClass='active' offset={-300} smooth={true} spy={true} to='contact' className='cursor-pointer w-[50px] h-[50px] flex items-center justify-center my-2'>
                        <BiPhone style={{ fontSize: '1.5rem' }} />
                    </Link>
                </div>
            </div>
        </nav>
    );
};

export default Nav;
