import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHeadset, faBuilding, faFileAlt, faLanguage} from '@fortawesome/free-solid-svg-icons';
import {language, capitalizeFirstLetter} from "../translation/language";

const Features3 = (props) => {
    return (
        <div className="w-full bg-white text-gray-700" id="features">
            <div className="mx-auto py-10 px-4 w-full max-w-7xl grid grid-cols-2 gap-6">
                <div className="col-span-2">
                    <h2 className="text-center text-4xl sm:text-5xl text-sky-800 font-bold tracking-wider mb-14">{capitalizeFirstLetter(language.why_we[props.language])}</h2>
                </div>
                <div className="col-span-2 sm:col-span-1">
                    <div className="w-full flex flex-col items-center bg-blue-200 p-4 rounded-lg">
            <span
                className="flex-shrink-0 p-2 w-8 sm:w-10 h-8 sm:h-10 inline-flex justify-center items-center rounded-full bg-sky-500"
                title="Online/Offline Support">
              <FontAwesomeIcon icon={faHeadset} className="w-6 h-6 text-white"/>
            </span>
                        <div className="mt-4 text-center">
                            <dt className="text-xl font-semibold">{capitalizeFirstLetter(language.online_offline_support[props.language])}</dt>
                            <dd className="mt-2 text-sm">{capitalizeFirstLetter(language.working_hours[props.language])}</dd>
                        </div>
                    </div>
                </div>
                <div className="col-span-2 sm:col-span-1">
                    <div className="w-full flex flex-col items-center bg-blue-200 p-4 rounded-lg">
            <span
                className="flex-shrink-0 p-2 w-8 sm:w-10 h-8 sm:h-10 inline-flex justify-center items-center rounded-full bg-teal-500 transition-transform transform hover:-translate-y-1"
                title="Offices in 6 Polish Cities">
              <FontAwesomeIcon icon={faBuilding} className="w-6 h-6 text-white"/>
            </span>
                        <div className="mt-4 text-center">
                            <dt className="text-xl font-semibold">{capitalizeFirstLetter(language.six_polish_cities_offices[props.language])}</dt>
                            <dd className="mt-2 text-sm">Biura w Warszawie, Gdańsku, Wrocławiu, Krakowie, Łózie,
                                Poznaniu
                            </dd>
                        </div>
                    </div>
                </div>
                <div className="col-span-2 sm:col-span-1">
                    <div className="w-full flex flex-col items-center bg-blue-200 p-4 rounded-lg">
            <span
                className="flex-shrink-0 p-2 w-8 sm:w-10 h-8 sm:h-10 inline-flex justify-center items-center rounded-full bg-yellow-800"
                title="Best Offers from Insurance Agencies">
              <FontAwesomeIcon icon={faFileAlt} className="w-6 h-6 text-white"/>
            </span>
                        <div className="mt-4 text-center">
                            <dt className="text-xl font-semibold">{capitalizeFirstLetter(language.best_offers_from_insurance_agencies[props.language])}</dt>
                            <dd className="mt-2 text-sm">{capitalizeFirstLetter(language.best_offers_from_insurance_agencies[props.language])}</dd>
                        </div>
                    </div>
                </div>
                <div className="col-span-2 sm:col-span-1">
                    <div className="w-full flex flex-col items-center bg-blue-200 p-4 rounded-lg">
            <span
                className="flex-shrink-0 p-2 w-8 sm:w-10 h-8 sm:h-10 inline-flex justify-center items-center rounded-full bg-yellow-400"
                title="Support for Foreigners (EN/PL/UA)">
              <FontAwesomeIcon icon={faLanguage} className="w-6 h-6 text-white"/>
            </span>
                        <div className="mt-4 text-center">
                            <dt className="text-xl font-semibold">Support for Foreigners (EN/PL/UA)</dt>
                            <dd className="mt-2 text-sm">{capitalizeFirstLetter(language.support_for_foreigners_languages[props.language])}</dd>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Features3;
