import {capitalizeFirstLetter, language} from "../translation/language";

const Button = (props) => {
    return (
        <div className="bg-white items-center justify-center">
            <button onClick={props.setIsOpen} className="mt-2 px-6 py-2 font-medium bg-blue-500  text-white w-fit transition-all shadow-[3px_3px_0px_black] hover:shadow-none hover:translate-x-[3px] hover:translate-y-[3px]">
                {props.children}
            </button>
        </div>
    );
};

export default Button;