import React, {Fragment, useState} from "react";
import classes from '../styles/InsuranceCards.module.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCar, faHeart, faHome, faSeedling, faBriefcase, faUser} from '@fortawesome/free-solid-svg-icons';
import ImageSlider from "./ImageSlider";
import Button from "./Button";
import OcGraniczne from '../assets/OcGraniczne.png'
import Touristic from '../assets/Touristic.png'
import {language, capitalizeFirstLetter} from "../translation/language";

const InsuranceCards = (props) => {

    const [isDropdownVisible, setDropdownVisible] = useState(false);

    return (
        <Fragment>
            <div className="flex flex-wrap container mx-auto justify-start">
                <div onClick={props.setIsOpen} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 p-2 mb-4">
                    <div
                        className="bg-white shadow-lg rounded-lg p-4 border border-gray-300 hover:shadow-xl transition-transform duration-300 transform hover:scale-105 cursor-pointer">
                        <FontAwesomeIcon icon={faCar} className="mr-2"/>
                        <div className="text-lg font-semibold">Komunikacyjne</div>
                    </div>
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 p-2 mb-4">
                    <div
                        onClick={props.setIsOpen} className="bg-white shadow-lg rounded-lg p-4 border border-gray-300 hover:shadow-xl transition-transform duration-300 transform hover:scale-105 cursor-pointer">
                        <FontAwesomeIcon icon={faHeart} className="mr-2"/>
                        <div className="text-lg font-semibold">Zdrowotne</div>
                    </div>
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 p-2 mb-4">
                    <div
                        onClick={props.setIsOpen} className="bg-white shadow-lg rounded-lg p-4 border border-gray-300 hover:shadow-xl transition-transform duration-300 transform hover:scale-105 cursor-pointer">
                        <FontAwesomeIcon icon={faHome} className="mr-2"/>
                        <div className="text-lg font-semibold">Majątkowe</div>
                    </div>
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 p-2 mb-4">
                    <div
                        onClick={props.setIsOpen} className="bg-white shadow-lg rounded-lg p-4 border border-gray-300 hover:shadow-xl transition-transform duration-300 transform hover:scale-105 cursor-pointer">
                        <FontAwesomeIcon icon={faSeedling} className="mr-2"/>
                        <div className="text-lg font-semibold">Rolnicze</div>
                    </div>
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 p-2 mb-4">
                    <div
                        onClick={props.setIsOpen} className="bg-white shadow-lg rounded-lg p-4 border border-gray-300 hover:shadow-xl transition-transform duration-300 transform hover:scale-105 cursor-pointer">
                        <FontAwesomeIcon icon={faBriefcase} className="mr-2"/>
                        <div className="text-lg font-semibold">Turystyczne</div>
                    </div>
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 p-2 mb-4">
                    <div
                        onClick={props.setIsOpen} className="bg-white shadow-lg rounded-lg p-4 border border-gray-300 hover:shadow-xl transition-transform duration-300 transform hover:scale-105 cursor-pointer">
                        <FontAwesomeIcon icon={faUser} className="mr-2"/>
                        <div className="text-lg font-semibold">Dla firm</div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto">
                <div className={`flex flex-col lg:flex-row w-full p-2 ${classes.productCard}`}>
                    <div className="w-full sm:w-1 md:w-1/2 lg:w-1/2 xl:w-1/2 p-2 mb-4">
                        <div
                            className="bg-white shadow-lg rounded-lg p-4 border border-gray-300 transition-transform transform hover:scale-105 flex relative">
                            <div className="flex-1">
                                <div className="text-lg font-semibold text-blue-500">Oc Graniczne</div>
                                <div className="text-gray-600 text-sm mb-2">Ubezpieczenie pojazdów zarejestrowanych za
                                    granicą
                                </div>
                                <div className="flex items-center relative">
                                    <span className="text-green-500 font-semibold text-lg">150.00zł</span>
                                    {/*<div className="relative inline-block">*/}
                                    {/*    <FaInfoCircle*/}
                                    {/*        className="cursor-pointer text-gray-400 hover:text-gray-600 ml-2"*/}
                                    {/*        onMouseEnter={() => setDropdownVisible(true)}*/}
                                    {/*        onMouseLeave={() => setDropdownVisible(false)}*/}
                                    {/*    />*/}
                                    {/*    {isDropdownVisible && (*/}
                                    {/*        <div*/}
                                    {/*            className="dropdown-menu absolute bg-white border border-gray-300 p-2 rounded-md mt-2 lg:left-6 left-0 w-96">*/}
                                    {/*            <div className="text-gray-700">*/}
                                    {/*                The price is shown for 1 month. 2 months cost 286; 3 months cost 409*/}
                                    {/*                .... 1 year costs 942zł.*/}
                                    {/*                For more information, call +48-571-850-172.*/}
                                    {/*            </div>*/}
                                    {/*            <div className="flex items-center mb-2">*/}
                                    {/*                <FaPhone className="text-blue-500 mr-2"/>*/}
                                    {/*                <span className="text-gray-700">+48-571-850-172</span>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    )}*/}
                                    {/*</div>*/}
                                </div>
                                <div>
                                    <Button setIsOpen={props.setIsOpen} className="bg-blue-500 text-white rounded-md px-4 py-2 mt-6 hover:bg-blue-700">
                                        {language.buy[props.language]}
                                    </Button>
                                </div>
                            </div>
                            <div className="w-1/3">
                                <img src={OcGraniczne} alt="OC graniczne"
                                     className="object-cover h-48 w-full rounded-lg"/>
                            </div>
                        </div>
                    </div>

                    <div className="w-full sm:w-1 md:w-1/2 lg:w-1/2 xl:w-1/2 p-2 mb-4">
                        <div
                            className="bg-white shadow-lg rounded-lg p-4 border border-gray-300 transition-transform transform hover:scale-105 flex relative">
                            <div className="flex-1">
                                <div className="text-lg font-semibold text-blue-500">Ubezpieczenie dla karty pobytu
                                </div>
                                <div className="text-gray-600 text-sm mb-2">Ubezpieczenie leczenia kosztów cudoziemców
                                    do 35000 euro
                                </div>
                                <div className="flex items-center relative">
                                    <span className="text-green-500 font-semibold text-lg">550.00zł</span>
                                </div>
                                <div >
                                    <Button setIsOpen={props.setIsOpen}  className="bg-blue-500 text-white rounded-md px-4 py-2 mt-6 hover:bg-blue-700">
                                        {language.buy[props.language]}
                                    </Button>
                                </div>
                            </div>
                            <div className="w-1/3">
                                <img src={Touristic} alt="Ubezpieczenie dla kart pobytu"
                                     className="object-cover h-48 w-full rounded-lg"/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <ImageSlider/>
        </Fragment>
    );
}

export default InsuranceCards;
